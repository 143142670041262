// import logo from './logo.svg';
import './App.css';
// import { Routes, Route, BrowserRouter } from "react-router-dom";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Projects from "./components/Projects";
import Experience from "./components/Experience";
import Footer from "./components/Footer";

function App() {
  return (
    // <BrowserRouter>
    //     <Routes>
    //       <Route path="/portfolio" element={<Home />} />
    //       <Route path="/portfolio/about" element={<About />} />
    //     </Routes>      
    // </BrowserRouter>
    <>
      <Navbar />
      <Hero />
      <Projects />
      <Experience />
      <Footer />
    </>
  );
}
export default App;
