import React, { useEffect, useRef } from 'react';
import './css/Footer.css';
import { animateScroll as scroll } from 'react-scroll';


const Footer = () => {
    const titleRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animated-text');
                } 
            });
        });

        if (titleRef.current) {
            observer.observe(titleRef.current);
        }

        return () => {
            if (titleRef.current) {
                observer.unobserve(titleRef.current);
            }
        };
    }, []);

    const scrollToTop = () => {
        scroll.scrollToTop();
    };
    return (
        <footer className='footer' id='footer'>
            <div className='footer-back'>
                <div className='container'>
                    <div className='footer-links'>
                        <div>
                            <ul className='social-list'>
                                <li className='social-list-item'>
                                    <a href='https://www.linkedin.com/in/roberto-hernandez-olvera' target='_blank' rel='noreferrer'>
                                        Linkedin
                                    </a>
                                </li>
                                <li className='social-list-item'>
                                    <a href='https://github.com/RobertoHzo' target='_blank' rel='noreferrer'>
                                        Github
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className='buttons-list' >
                            <a href="mailto:roberto.hernandezo@hotmail.com">
                                <button type='button' className='footer-btn contact-btn'>Contact me</button>
                            </a>
                            <button className='footer-btn toTopBtn' onClick={scrollToTop}>
                                <span className="material-symbols-outlined">
                                    north
                                </span>
                            </button>
                        </div>
                    </div>
                    <div ref={titleRef}>
                        <p className='footer-name' >ROBERTO HERNANDEZ</p>
                        <p className='footer-name-mobile'>RH<span>o</span></p>
                    </div>
                </div>
            </div>
        </footer>
    );

};

export default Footer;
