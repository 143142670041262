import React from 'react';
import './css/Hero.css';

const Home = () => {
    return (
        <section className='hero' id='hero'>
            <div className='hero-gradient'></div>
            <div className='container hero-main'>
                <p className='title animated-text'>
                    Hello, I'm <strong>Roberto Hernandez</strong>
                </p>
                <p className='subtitle'>
                    A software developer based in Mexico in constant search of challenges and creative solutions.
                </p>
                <div className='hero-buttons'>
                    <a href="mailto:roberto.hernandezo@hotmail.com" className='btn contact-btn'>
                        Contact Me
                    </a>
                    <a href='https://github.com/RobertoHzo' target='_blank' rel='noreferrer' className='btn link-btn'>
                        <img src='/assets/logos/github-mark-white.png' alt='github logo'></img>
                    </a>
                    <a href='https://www.linkedin.com/in/roberto-hernandez-olvera' target='_blank' rel='noreferrer' className='btn link-btn'>
                        <img src='/assets/logos/LI-In-Bug.png' alt='linkedin logo'></img>
                    </a>
                </div>
            </div >
        </section>
    );
};

export default Home;
