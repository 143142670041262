import React, { useEffect, useRef } from 'react';
import './css/Experience.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 6
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 6
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 4
    },
    mobile: {
        breakpoint: { max: 464, min: 385 },
        items: 3
    },
    miniMobile: {
        breakpoint: { max: 385, min: 0 },
        items: 2
    }
};

const Experience = () => {
    const titleRef = useRef(null);

    useEffect(() => {
        // 1. Crea un nuevo IntersectionObserver
        const observer = new IntersectionObserver(entries => {
            // 2. Callback para cada entrada del observer
            entries.forEach(entry => {
                // 3. Verifica si el elemento está visible
                if (entry.isIntersecting) {
                    // 4. Agrega la clase 'animated-text' si está visible
                    entry.target.classList.add('animated-text');
                 } 
                //else {
                //     // 5. Remueve la clase 'animated-text' si no está visible
                //     entry.target.classList.remove('animated-text');
                // }
            });
        });

        // 6. Observa el elemento referenciado por titleRef si existe
        if (titleRef.current) {
            observer.observe(titleRef.current);
        }

        // 7. Limpieza al desmontar el componente
        return () => {
            if (titleRef.current) {
                observer.unobserve(titleRef.current);
            }
        };
    }, []);

    return (
        <section className='experience' id='experience'>
            <div className='container experience-container'>
                <h2 className='experience-title' ref={titleRef}>EXPERIENCE</h2>
                <div className='timeline'>
                    <div className='timeline-item'>
                        <div className='exp-company'>
                            <h3>Landis + Gyr</h3>
                            <p>Software intern | 02/23 - 08/23</p>

                        </div>
                        <dl className='exp-description'>
                            <dt><strong>Application Development and Enhancement:</strong></dt>
                            <dd>• Enhancement of web and desktop applications in .NET.</dd>
                            <dd>• Development of new features in web applications with Angular.</dd>
                            <dt><strong>Database Management:</strong></dt>
                            <dd>• Creating and maintaining stored procedures and functions in Oracle
                                databases.</dd>
                            <dt><strong>Technical Skills:</strong></dt>
                            <dd>• Handling of APIS in C# and maintenance of code in Visual Basic.</dd>
                        </dl>
                    </div>
                    <div className='timeline-item'>
                        <div className='exp-company'>
                            <h3>Panasonic automotive systems de México</h3>
                            <p>Development intern | 09/22 - 01/23</p>
                        </div>
                        <dl className='exp-description'>
                            <dt><strong>Application Development:</strong></dt>
                            <dd>• Development of desktop applications with Windows Forms.</dd>
                            <dd>• Maintenance and enhancement of web applications in ASP.NET Core.</dd>
                            <dd>• Implementation of functionality to measure the percentage of failures in
                                production testing machines with ASP.NET Core.</dd>
                            <dt><strong>Database Management and Technical Support:</strong></dt>
                            <dd>• Creation and maintenance of stored procedures in SQL databases.</dd>
                            <dd>• Technical support to users, especially with applications that display work
                                instructions.</dd>
                        </dl>
                    </div>
                </div>
                <div className='carousel'>
                    <Carousel
                        swipeable={false}
                        draggable={false}
                        additionalTransfrom={0}
                        showDots={false}
                        responsive={responsive}
                        infinite
                        rewind={false}
                        rewindWithAnimation={false}
                        rtl={false}
                        autoPlay={true}
                        autoPlaySpeed={1500}
                        arrows={false}
                        keyBoardControl={false}
                        customTransition="all 1s linear"
                        containerClass="carousel-container"
                        slidesToSlide={1}
                        centerMode={true}
                        itemClass="carousel-item">
                        <div><img src='/assets/logos/angular-logo.png' loading="lazy" alt='angular'></img></div>
                        <div><img src='/assets/logos/brand-dotnet.png' loading="lazy" alt='dotnet'></img></div>
                        <div><img src='/assets/logos/csharp.png' loading="lazy" alt='csharp'></img></div>
                        <div><img src='/assets/logos/figma-logo.png' loading="lazy" alt='figma'></img></div>
                        <div><img src='/assets/logos/javascript-logo.png' loading="lazy" alt='javascript'></img></div>
                        <div><img src='/assets/logos/laravel-logo.png' loading="lazy" alt='laravel'></img></div>
                        <div><img src='/assets/logos/typescript-logo.png' loading="lazy" alt='typescript'></img></div>
                        <div><img src='/assets/logos/php-logo.png' loading="lazy" alt='php'></img></div>
                        <div><img src='/assets/logos/react-logo.png' loading="lazy" alt='react'></img></div>
                        <div><img src='/assets/logos/sql-logo.png' loading="lazy" alt='sql'></img></div>
                    </Carousel>
                </div>
            </div>
        </section>
    );
}

export default Experience;
