import React, { useEffect, useRef } from 'react';
import './css/Projects.css';

const Projects = () => {
    const titleRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animated-text');
                } 
            });
        });

        if (titleRef.current) {
            observer.observe(titleRef.current);
        }

        return () => {
            if (titleRef.current) {
                observer.unobserve(titleRef.current);
            }
        };
    }, []);
    return (
        <section className='projects' id='projects'>
            <div className='projects-back'>
                <div className='container'>
                    <h2 className='projects-title' ref={titleRef}>PROJECTS</h2>
                    <div className="card">
                        <a href='https://github.com/RobertoHzo/RestaurantWebApp_Laravel' target='_blank' rel='noreferrer'>
                            <img className="card-img" src='/assets/projects/restaurant/mockup.webp' alt="Title" />
                        </a>
                        <div className="card-body">
                            <h4 className="card-title">Restaurant application with online ordering system</h4>
                            <div className="card-text">
                                <p >Web application</p>
                                <a href='https://github.com/RobertoHzo/RestaurantWebApp_Laravel' target='_blank' rel='noreferrer'>
                                    <span className="material-symbols-outlined card-link">
                                        arrow_outward
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="card card-left">
                        <a href='https://github.com/RobertoHzo/Abarrotes-Inventario-catalogo' target='_blank' rel='noreferrer'>
                            <img className="card-img" src='/assets/projects/grocery_store/mockup.webp' alt="Title" />
                        </a>
                        <div className="card-body">
                            <h4 className="card-title">Grocery store inventory system</h4>
                            <div className="card-text">
                                <p >Web application</p>
                                <a href='https://github.com/RobertoHzo/Abarrotes-Inventario-catalogo' target='_blank' rel='noreferrer'>
                                    <span className="material-symbols-outlined card-link">
                                        arrow_outward
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <a href='https://www.figma.com/file/FiTlGCplx1P8UeVdDiwdbs/Library-system-prototype?type=design&node-id=1%3A1935&mode=design&t=gpgwM7QX1GfOHGmw-1' target='_blank' rel='noreferrer'>
                            <img className="card-img" src='/assets/projects/library_design/mockup.webp' alt="Library design" />
                        </a>
                        <div className="card-body">
                            <h4 className="card-title">Library app UI design</h4>
                            <div className="card-text">
                                <p >UI design</p>
                                <a href='https://www.figma.com/file/FiTlGCplx1P8UeVdDiwdbs/Library-system-prototype?type=design&node-id=1%3A1935&mode=design&t=gpgwM7QX1GfOHGmw-1' target='_blank' rel='noreferrer'>
                                    <span className="material-symbols-outlined card-link">
                                        arrow_outward
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Projects;
