import { React } from "react";
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import "./css/Navbar.css";

const Navbar = () => {
      return (
            <div className="navbar-background">
                  <nav className='navbar'>
                        <Link
                              to="hero"
                              smooth='easeInQuad'
                              offset={-1}
                              duration={500}
                              className="logo"
                        >
                              RH<span>o</span>
                        </Link>
                        {/* <div className="logo">RH<span>o</span></div> */}
                        <div className="menu">
                              <Link
                                    to="projects"
                                    smooth='easeInQuad'
                                    offset={-1}
                                    duration={500}
                                    className="navbar-link"
                              >
                                    Projects
                              </Link>
                              <Link
                                    to="experience"
                                    smooth='easeInQuad'
                                    offset={0}
                                    duration={500}
                                    className="navbar-link"
                              >
                                    Experience
                              </Link>
                              <a href="mailto:roberto.hernandezo@hotmail.com">
                                    <button type='button' className='nav-btn'>Contact</button>
                              </a>
                        </div>
                  </nav>
            </div>
      );

};
export default Navbar;